import React from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { accountIsLoggedInSelector } from 'state/account/account.selectors';
import useSecurePage from 'hooks/useSecurePage';

export interface WithSecurityProps {
    isLoggedIn: boolean;
    isSecurePage: boolean;
}

const withSecurity = (Component: any) => (props: any) => {
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const { isSecurePage } = useSecurePage();

    if (isSecurePage && !isLoggedIn && typeof window !== 'undefined') {
        navigate('/sign-in');
        return null;
    }
    return <Component {...props} isSecurePage={isSecurePage} isLoggedIn={isLoggedIn} />;
};

export default withSecurity;
