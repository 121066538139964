import React from 'react';

import { LogoProps } from 'ui-kit/logo/logo.props';

const PillLogo = ({ style, variant }: LogoProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'dark': {
                return { fill: 'rgb(34,42,63)', fillReverse: 'rgb(3,164,220)', height: '100%', width: '100%' };
            }
            case 'dark-large': {
                return {
                    fill: 'rgb(34,42,63)',
                    fillReverse: 'rgb(3,164,220)',
                    height: '276.346',
                    width: '179.449'
                };
            }
            case 'primary-large': {
                return { fill: 'rgb(3,164,220)', fillReverse: 'rgb(255,255,255)', height: '276.346', width: '179.449' };
            }
            case 'primary': {
                return { fill: 'rgb(3,164,220)', fillReverse: 'rgb(255,255,255)', height: '68', width: '34' };
            }
            default: {
                return { fill: 'rgb(3,164,220)', fillReverse: 'rgb(255,255,255)', height: '100%', width: '100%' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={variantStyle.width}
            height={variantStyle.height}
            viewBox="0 0 179.449 276.346"
            style={style}
        >
            <defs>
                <rect id="rect-1" width={variantStyle.width} height={variantStyle.height} x="0" y="0" />
            </defs>
            <g>
                <path
                    fill={variantStyle.fill}
                    fillRule="evenodd"
                    d="M.062 85.42H.011v.993c0 .105-.011.204-.011.315 0 .104.011.204.011.308v99.274c0 .103-.011.209-.011.314 0 .105.011.202.011.308v4.674h.242c2.594 47.221 41.602 84.74 89.473 84.74 47.875 0 86.875-37.519 89.477-84.74h.246V85.42l-.063 3C178.679 39.473 138.835 0 89.726 0 40.613 0 .769 39.473.062 88.42"
                />
                <path
                    fill={variantStyle.fillReverse}
                    fillRule="evenodd"
                    d="M79.80139923 111.5251007c4.6399994-1.63000487 9.6269989-2.52600097 14.82500458-2.52600097 24.70599365 0 44.73498535 20.02900696 44.73498535 44.73500061 0 24.70700073-20.0289917 44.73599243-44.73498535 44.73599243-7.28700256 0-14.16100311-1.7539978-20.2400055-4.84500122-3.21099853 3.13500977-7.59300231 5.07200623-12.43899917 5.07200623-9.52499771 0-17.28699875-7.46499634-17.8030014-16.86199951h-.0489998v-.93000794c0-.0209961-.0019989-.0409851-.0019989-.06098938 0-.0209961.0019989-.04200744.0019989-.06300354V89.56510162c0-.02100372-.0019989-.04100036-.0019989-.06200409 0-.02099609.0019989-.04100036.0019989-.06199646v-.19799804h.01000215c.14099884-9.7400055 8.06900024-17.59400177 17.84199905-17.59400177 9.7729988 0 17.70100021 7.85399627 17.84199905 17.59400177h.01200104v22.28199768zm-.19599914 42.20899964c0 8.29599 6.72599792 15.0209961 15.02100372 15.0209961 8.29499817 0 15.01999664-6.7250061 15.01999664-15.0209961 0-8.29499817-6.72499847-15.02000427-15.01999664-15.02000427-8.2950058 0-15.02100372 6.7250061-15.02100372 15.02000427z"
                />
            </g>
        </svg>
    );
};

export default PillLogo;
