import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import SEOProps from './seo.props';

const SEO = ({ nodeTitle, title, description, abstract, keywords, image }: SEOProps) => {
    const { pathname } = useLocation();
    const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(query);

    const {
        defaultTitle,
        defaultDescription,
        defaultAbstract,
        defaultKeywords,
        siteUrl,
        siteName,
        defaultImage
    } = site.siteMetadata;

    function cleanTitle(title: String): String {
        title = title.replace('[node:title]', nodeTitle ? nodeTitle : siteName);
        title = title.replace('[site:name]', siteName);
        return title;
    }

    const seo = {
        title: cleanTitle(title || defaultTitle),
        description: description || defaultDescription,
        abstract: abstract || defaultAbstract,
        keywords: keywords || defaultKeywords,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`
    };

    return (
        <Helmet
            htmlAttributes={{
                lang: 'en'
            }}
            title={seo.title}
        >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="keywords" content={seo.keywords} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            <meta property="og:type" content="website" />
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
        </Helmet>
    );
};

export default SEO;

SEO.defaultProps = {
    nodeTitle: null,
    title: null,
    description: null,
    abstract: null,
    image: null,
    keywords: null
};

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                siteName: siteName
                defaultTitle: title
                defaultDescription: description
                defaultAbstract: abstract
                siteUrl: url
                defaultImage: image
            }
        }
    }
`;
