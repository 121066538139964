import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useDispatch } from 'react-redux';
import { logout } from 'state/account/account.reducers';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';

import { NavPath, MenuProps } from 'components/navigation/navigation.props';

import CloseIcon from 'ui-kit/icons/close-icon/close-icon';
import Logo from 'ui-kit/logo/logo';
import Link from 'ui-kit/link/link';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import MobileToggle from 'ui-kit/mobile-toggle/mobile-toggle';
import PillLogo from 'ui-kit/logo/pill-logo/pill-logo';
import Button from 'ui-kit/button/button';

import './mobile-menu.style.scss';
import 'ui-kit/utils/scroll-lock/scroll-lock.style.scss';

import {
    IntraPageMenuItemProps,
    IntraPageMenuItemContentValue
} from 'components/navigation/intra-page-menu/intra-page-menu.props';

const MobileMenu = ({ isScrolled, paths, userName, profileMenu, isLoggedIn, cartItemsTotal }: MenuProps) => {
    const [navPaths, setNavPaths] = useState<NavPath[] | undefined>();
    const [cartTotal, setCartTotal] = useState(cartItemsTotal);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const toggleMobileMenuVisibility = () => {
        document.body.classList.toggle('scroll-lock');
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        setNavPaths(paths);
    }, [paths, isLoggedIn]);

    useEffect(() => {
        setCartTotal(cartItemsTotal);
    }, [cartItemsTotal]);

    return (
        <Container
            fluid
            className={`mobile-menu d-lg-none justify-content-center ${
                isMobileMenuVisible ? 'menu-open' : 'menu-closed'
            }`}
        >
            <Row className="w-100">
                <Col xs={8} sm={8} md={8} lg={8} className="d-flex justify-content-start">
                    <Link
                        to={'/'}
                        label={
                            <>
                                <div className="co-brand-logo d-flex"></div>
                                {isScrolled ? <PillLogo variant="primary" /> : <Logo variant="primary" />}
                            </>
                        }
                        variant="site"
                        dataGALocation="HeaderLogo"
                    />
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end align-items-center">
                    <MobileToggle
                        variant="primary"
                        onClick={() => toggleMobileMenuVisibility()}
                        style={{ height: '2.625rem', width: '2.625rem' }}
                    />
                </Col>
            </Row>
            <div className="menu">
                <div className="menu-header">
                    <div className="d-flex justify-content-start">
                        <div className="co-brand-logo d-flex"></div>
                        <Logo variant="light" />
                    </div>
                    <div className="d-flex justify-content-end">
                        <CloseIcon
                            onClick={() => toggleMobileMenuVisibility()}
                            style={{ height: '3rem', width: '3rem' }}
                        />
                    </div>
                </div>
                <div className="menu-links">
                    {navPaths &&
                        navPaths.map(({ link, label, isButton }: NavPath) => (
                            <React.Fragment key={`site-mobile-nav-${link?.url}-${label}`}>
                                {!isButton && <div className="menu-spacer" />}
                                <Link
                                    to={link?.url}
                                    label={label}
                                    variant={Boolean(isButton) === true ? 'nav-button' : 'nav'}
                                    dataGALocation="HeaderNav"
                                />
                            </React.Fragment>
                        ))}
                    {profileMenu && (
                        <>
                            <Link
                                to={'/secure/cart'}
                                label={
                                    <>
                                        <CartIcon
                                            className={'position-absolute border-cathams-blue'}
                                            itemCount={cartTotal}
                                            style={{ left: '0' }}
                                        />
                                        <small>{t('menus.cart.title')}</small>
                                    </>
                                }
                                variant={'nav'}
                                dataGALocation="HeaderNav"
                                className="btn btn-outline-smoke mt-4 px-4 text-center w-100"
                            />

                            <div className="menu-links-profile">
                                <h5 className="user-name">{userName}</h5>
                                {profileMenu.map(
                                    ({ key, value, label }: IntraPageMenuItemProps<IntraPageMenuItemContentValue>) => (
                                        <React.Fragment key={`site-mobile-nav-profile-${key}`}>
                                            <Link
                                                to={value.toString()}
                                                label={label}
                                                variant={'nav'}
                                                dataGALocation="HeaderNav"
                                            />
                                        </React.Fragment>
                                    )
                                )}
                                <div className="link" data-variant="nav">
                                    <Button
                                        variant="text"
                                        type="button"
                                        onClick={() => {
                                            dispatch(logout({}));
                                            navigate('/sign-in');
                                            return false;
                                        }}
                                        label={t('menus.intraPage.profile.signOut')}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default MobileMenu;
