import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useDispatch } from 'react-redux';
import { logout } from 'state/account/account.reducers';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { MenuProps, NavPath } from 'components/navigation/navigation.props';

import PillLogo from 'ui-kit/logo/pill-logo/pill-logo';
import Logo from 'ui-kit/logo/logo';
import Link from 'ui-kit/link/link';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';

import './desktop-menu.style.scss';

import {
    IntraPageMenuItemProps,
    IntraPageMenuItemContentValue
} from 'components/navigation/intra-page-menu/intra-page-menu.props';

const DesktopMenu = ({ isScrolled, paths, userName, profileMenu, isLoggedIn, cartItemsTotal }: MenuProps) => {
    const [navPaths, setNavPaths] = useState<NavPath[] | undefined>();
    const [cartTotal, setCartTotal] = useState(cartItemsTotal);
    const logoPng = require('../../../assets/images/shlogo.png');

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        setNavPaths(paths);
    }, [paths, isLoggedIn]);

    useEffect(() => {
        setCartTotal(cartItemsTotal);
    }, [cartItemsTotal]);

    return (
        <Container fluid="xl" className="desktop-menu d-none d-lg-flex">
            <Navbar.Brand>
                <Link
                    to={'/'}
                    label={
                        <>
                            <div className="co-brand-logo d-flex"></div>
                            {isScrolled ? (
                                <PillLogo variant="primary" />
                            ) : (
                                <>
                                    <img src={logoPng} className="shlogo" alt="" />
                                    <span className="shlogo-border" />
                                    <Logo variant="primary" />
                                </>
                            )}
                        </>
                    }
                    variant="site"
                    dataGALocation="HeaderLogo"
                />
            </Navbar.Brand>
            <Navbar.Collapse id="site-navbar-paths" className="justify-content-end">
                <Nav>
                    {navPaths &&
                        navPaths.map(({ link, label, isButton }: NavPath) => (
                            <Link
                                key={`site-nav-${link.url}`}
                                to={link.url}
                                label={label}
                                variant={Boolean(isButton) === true ? 'nav-button' : 'nav'}
                                dataGALocation="HeaderNav"
                            />
                        ))}
                    {profileMenu && (
                        <>
                            <div className="menu-links-profile">
                                {/* DEMO: Hack in a user menu. */}
                                <DropdownButton id="dropdown-basic-button" title="John Smith">
                                    <div className="demo-profile">
                                        Your credit of <span>$30.00</span> expires 7/31/21
                                    </div>
                                    {/* <DropdownButton id="dropdown-basic-button" title={userName}> */}
                                    {/* {profileMenu.map(
                                        ({
                                            key,
                                            value,
                                            label
                                        }: IntraPageMenuItemProps<IntraPageMenuItemContentValue>) => (
                                            <Dropdown.Item
                                                key={`profile-item-${key}`}
                                                as={Link}
                                                to={value}
                                                label={label}
                                                data-tagtype="anchorlink"
                                                dataGALocation="HeaderNav"
                                            />
                                        )
                                    )} */}
                                    <Dropdown.Item
                                        key={'profile-item-logout'}
                                        onClick={() => {
                                            dispatch(logout({}));
                                        }}
                                        data-tagtype="anchorlink"
                                        data-ga-location="HeaderNav"
                                    >
                                        {t('menus.intraPage.profile.signOut')}
                                    </Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <Link
                                to={'/secure/cart'}
                                label={<CartIcon itemCount={cartTotal} className={'border-white'} />}
                                variant={'nav'}
                                className={'no-active-state'}
                                dataGALocation="HeaderNav"
                            />
                        </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Container>
    );
};

export default DesktopMenu;
