import React from 'react';

import { CloseIconProps } from 'ui-kit/icons/close-icon/close-icon.props';

import './close-icon.style.scss';

const CloseIcon = ({ onClick, style }: CloseIconProps) => {
    return (
        <div className="close-icon-container" style={style}>
            <svg
                className="close-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                viewBox="0 0 30 30"
                onClick={onClick ?? undefined}
            >
                <defs>
                    <ellipse id="ellipse-1" cx="15" cy="15" rx="15" ry="15" />
                    <mask id="mask-2" x="0" y="0" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
                        <rect width="30" height="30" x="0" y="0" fill="black" />
                        <use fill="white" xlinkHref="#ellipse-1" />
                    </mask>
                </defs>
                <g>
                    <use
                        fillOpacity="0"
                        stroke="rgb(255,255,255)"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="4.5"
                        mask="url(#mask-2)"
                        xlinkHref="#ellipse-1"
                    />
                    <g>
                        <path
                            fill="none"
                            stroke="rgb(255,255,255)"
                            strokeLinecap="round"
                            strokeLinejoin="miter"
                            strokeWidth="3.75"
                            d="M11 11.2l8 7.6"
                        />
                        <path
                            fill="none"
                            stroke="rgb(255,255,255)"
                            strokeLinecap="round"
                            strokeLinejoin="miter"
                            strokeWidth="3.75"
                            d="M19 11.2l-8 7.6"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default CloseIcon;
