import { ReactElement } from 'react';
import { TFunction } from 'gatsby-plugin-react-i18next';

import {
    IntraPageMenuItemProps,
    IntraPageMenuItemContentValue
} from 'components/navigation/intra-page-menu/intra-page-menu.props';

export interface ProfileLayoutProps {
    children?: ReactElement | ReactElement[];
    eyebrowText?: string;
    title?: string;
    heading?: string;
    subhead?: any;
}

const intraPageMenuKeys = {
    PERSONAL_INFORMATION: 'personalInformation',
    SHIPPING_ADDRESS: 'shippingAddress',
    SECURITY: 'security',
    HEALTH_PROFILE: 'healthProfile',
    INSURANCE: 'insurance',
    PAYMENT: 'payment',
    NOTIFICATIONS: 'notifications',
    MESSAGES: 'messages',
    CART: 'cart'
};

export const getTranslatedIntraPageMenuItems = (translation: TFunction<string>) => {
    const intraPageMenuItems: Array<IntraPageMenuItemProps<IntraPageMenuItemContentValue>> = [
        {
            key: intraPageMenuKeys.PERSONAL_INFORMATION,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.PERSONAL_INFORMATION}`),
            value: '/secure/profile/personal-info'
        },
        {
            key: intraPageMenuKeys.SHIPPING_ADDRESS,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.SHIPPING_ADDRESS}`),
            value: '/secure/profile/shipping-address'
        },
        {
            key: intraPageMenuKeys.SHIPPING_ADDRESS,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.SHIPPING_ADDRESS}`),
            value: '/secure/profile/shipping-address/add-new-address',
            hidden: true
        },
        {
            key: intraPageMenuKeys.SHIPPING_ADDRESS,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.SHIPPING_ADDRESS}`),
            value: '/secure/profile/shipping-address/edit-address',
            hidden: true
        },
        {
            key: intraPageMenuKeys.SECURITY,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.SECURITY}`),
            value: '/secure/profile/security'
        },
        {
            key: intraPageMenuKeys.HEALTH_PROFILE,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.HEALTH_PROFILE}`),
            value: '/secure/profile/health-profile'
        },
        {
            key: intraPageMenuKeys.INSURANCE,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.INSURANCE}`),
            value: '/secure/profile/insurance'
        },
        {
            key: intraPageMenuKeys.PAYMENT,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.PAYMENT}`),
            value: '/secure/profile/payment'
        },
        {
            key: intraPageMenuKeys.PAYMENT,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.PAYMENT}`),
            value: '/secure/profile/payment/add-new-payment',
            hidden: true
        },
        {
            key: intraPageMenuKeys.CART,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.CART}`),
            value: '/secure/cart',
            hidden: true
        },
        {
            key: intraPageMenuKeys.NOTIFICATIONS,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.NOTIFICATIONS}`),
            value: '/secure/profile/notifications',
            hidden: true
        },
        {
            key: intraPageMenuKeys.MESSAGES,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.MESSAGES}`),
            value: '/secure/profile/messages',
            hidden: true
        },
        {
            key: intraPageMenuKeys.MESSAGES,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.MESSAGES}`),
            value: '/secure/profile/messages/ask-pharm',
            hidden: true
        },
        {
            key: intraPageMenuKeys.MESSAGES,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.MESSAGES}`),
            value: '/secure/profile/messages/contact-us',
            hidden: true
        }
    ];
    return intraPageMenuItems;
};
