import React from 'react';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal, ModalCTAButton } from 'state/birdi-modal/birdi-modal.reducers';
import {
    birdiModalClassnameSelector,
    birdiModalContentSelector,
    birdiModalOnCloseSelector,
    birdiModalOpenSelector,
    birdiModalShowCloseSelector
} from 'state/birdi-modal/birdi-modal.selectors';

import Button from 'ui-kit/button/button';

import './birdi-modal.style.scss';

/**
 * A Modal that should be present in all the pages, this only activates if the prop isOpen
 * set to true.
 * @param {ModalProps} props Modal props to use for managing state of the modal.
 * @return {JSX.Element} Returns a rendered modal, that may stay hidden until required.
 */
export default function BirdiModal() {
    const isOpened = useSelector(birdiModalOpenSelector);
    const onClose = useSelector(birdiModalOnCloseSelector);
    const showCloseButton = useSelector(birdiModalShowCloseSelector);
    const className = useSelector(birdiModalClassnameSelector);
    const content = useSelector(birdiModalContentSelector);
    const dispatch = useDispatch();

    return (
        <ModalRender
            className={className}
            contentClassName={content.contentClassName}
            onHide={() => {
                dispatch(closeModal({})); //TODO: Need to confirm this is the default functionality
            }}
            showCloseButton={showCloseButton}
            onClose={onClose}
            isOpened={isOpened}
            bodyContent={content.bodyContent}
            ctas={content.ctas}
        />
    );
}

export interface ModalRenderProps {
    className?: string;
    contentClassName?: string;
    isOpened: boolean;
    bodyContent?: JSX.Element;
    ctas: ModalCTAButton[];
    showCloseButton: boolean;
    onHide: () => void;
    onClose?: () => void;
}
export const ModalRender = React.memo(function ({
    className,
    contentClassName,
    isOpened,
    bodyContent,
    ctas,
    showCloseButton,
    onClose,
    onHide
}: ModalRenderProps) {
    const dialogClasses = classNames(className, 'birdi-modal mt-5 mx-xs-5 mx-xs-auto');
    const contentClasses = classNames('birdi-modal__content', contentClassName);
    const handleHide = () => {
        if (onHide) onHide();
        if (onClose) onClose();
    };
    const modalTitle =
        bodyContent && bodyContent?.props?.title ? bodyContent.props.title.replace(/ /g, '') : 'unknown-modal';
    return (
        <Modal
            size={'xl'}
            dialogClassName={dialogClasses}
            contentClassName={contentClasses}
            show={isOpened}
            onHide={handleHide}
            data-ga-type="modal"
        >
            <Modal.Header closeButton={showCloseButton}></Modal.Header>
            <Modal.Body>{bodyContent && bodyContent}</Modal.Body>
            <Modal.Footer className={'d-flex flex-column justify-content-center birdi-modal__footer'}>
                {ctas.map((cta, idx) => (
                    <Button
                        dataGAType={'modal'}
                        dataGALocation={modalTitle}
                        key={`modal-footer-button-${idx}`}
                        {...cta}
                        type={'button'}
                    />
                ))}
            </Modal.Footer>
        </Modal>
    );
});
