import React from 'react';

import { LogoProps } from 'ui-kit/logo/logo.props';

const Logo = ({ variant }: LogoProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'light': {
                return { fill: 'rgb(255,255,255)', height: '48', width: '160' };
            }
            case 'light-large': {
                return { fill: 'rgb(255,255,255)', height: '167', width: '500' };
            }
            case 'primary-large': {
                return { fill: 'rgb(3,164,220)', height: '167', width: '500' };
            }
            case 'primary': {
                return { fill: 'rgb(3,164,220)', height: '48', width: '160' };
            }
            default: {
                return { fill: 'rgb(3,164,220)', height: '100%', width: '100%' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={variantStyle.width}
                height={variantStyle.height}
                viewBox="0 0 501 167.467"
            >
                <defs>
                    <rect id="rect-1" width="501" height="167" x="0" y="0" />
                </defs>
                <g transform="translate(-18 .0004)">
                    <path
                        fill={variantStyle.fill}
                        fillRule="evenodd"
                        d="M47.06740134 52.56259973c6.11699677-2.14899827 12.68899918-3.32999802 19.54100037-3.32999802 32.5660019 0 58.96699524 26.40000152 58.96699524 58.96699524 0 32.56700134-26.40099335 58.9670105-58.96699524 58.9670105-9.60500336 0-18.66600037-2.31100464-26.6800003-6.38500977-4.23100281 4.1309967-10.00800133 6.68499756-16.39400101 6.68499756-12.55600071 0-22.78700042-9.83999634-23.4680004-22.22599793h-.064v-1.22599792c0-.02799988-.002-.05400085-.002-.08099365 0-.02799988.002-.05500794.002-.08200074V23.61559922c0-.02799988-.002-.0529995-.002-.08099937 0-.02899933.002-.0550003.002-.08200073v-.26099968h.013C.2014 10.35360009 10.65140002-.0004 23.5344004-.0004c12.88100052 0 23.33200073 10.3540001 23.51599884 23.19199944h.0170021v29.37100029zm-.25800323 55.63699722c0 10.93499756 8.86400223 19.79899597 19.7990036 19.79899597 10.93499756 0 19.79899597-8.86399841 19.79899597-19.79899597 0-10.93500519-8.86399841-19.7990036-19.79899597-19.7990036-10.93500137 0-19.7990036 8.86399841-19.7990036 19.7990036z"
                    />
                    <path
                        fill={variantStyle.fill}
                        fillRule="evenodd"
                        d="M453.9258 78.0451h-.013v.26c0 .028-.003.054-.003.083 0 .027.003.053.003.081v65.382c0 .027-.003.055-.003.082 0 .028.003.054.003.081v1.226h.065c.679 12.386 10.911 22.227 23.467 22.227 12.557 0 22.786-9.841 23.469-22.227h.064v-67.195h-.017c-.184-12.838-10.636-23.192-23.516-23.192-12.882 0-23.333 10.354-23.519 23.192"
                    />
                    <path
                        fill={variantStyle.fill}
                        fillRule="evenodd"
                        d="M276.707 55.1579c-9.315.156-18.42 1.934-26.969 5.129-4.074-3.389-9.304-5.434-15.019-5.434-12.882 0-23.333 10.354-23.518 23.192h-.014v.261c0 .027-.002.053-.002.082 0 .028.002.054.002.081v65.382c0 .028-.002.055-.002.082 0 .028.002.054.002.081v1.227h.065c.68 12.385 10.911 22.226 23.467 22.226 12.557 0 22.786-9.841 23.469-22.226h.065v-30.69c0-5.66 3.601-10.758 8.995-12.474 3.458-1.1 7.097-1.677 10.803-1.677 12.666 0 22.901-10.408 22.621-23.138-.277-12.538-11.426-22.315-23.965-22.104"
                    />
                    <path
                        fill={variantStyle.fill}
                        fillRule="evenodd"
                        d="M143.9922 120.0737v23.778c0 .027-.003.054-.003.082 0 .027.003.053.003.081v1.226h.064c.68 12.385 10.912 22.226 23.467 22.226 12.558 0 22.786-9.841 23.47-22.226h.064v-25.167h-47.065z"
                    />
                    <path
                        fill={variantStyle.fill}
                        fillRule="evenodd"
                        d="M143.9922 102.2463h47.065v-24.201h-.017c-.185-12.839-10.636-23.192-23.517-23.192-12.882 0-23.332 10.353-23.517 23.192h-.014v.26c0 .028-.003.054-.003.083 0 .027.003.053.003.081v23.777z"
                    />
                    <path
                        fill={variantStyle.fill}
                        fillRule="evenodd"
                        d="M365.84490967 167.16670745c-32.5670166 0-58.9670105-26.40000916-58.9670105-58.9670105 0-32.56699372 26.3999939-58.96699524 58.9670105-58.96699524 6.85098266 0 13.42398071 1.18099975 19.5409851 3.32999802v-29.3710003h.01602173C385.58691406 10.3537001 396.03790283-.0003 408.91888428-.0003c12.88302612 0 23.33300781 10.3540001 23.51901245 23.19199944h.01300049v.26099967c0 .02700043.00299072.0530014.00299072.08200074 0 .02799988-.00299072.0529995-.00299072.08099937V143.851705c0 .0269928.00299072.05400086.00299072.08200074 0 .0269928-.00299072.05299377-.00299072.08099365v1.22599792h-.06399536c-.68099976 12.3860016-10.9119873 22.22599793-23.46801758 22.22599793-6.38699341 0-12.16299439-2.55400086-16.39498902-6.68499756-8.01300048 4.07400513-17.07400512 6.38500977-26.6789856 6.38500977zm0-78.76600647c-10.93499756 0-19.79800415 8.86399841-19.79800415 19.7990036 0 10.93499756 8.86300659 19.79899597 19.79800415 19.79899597 10.93399048 0 19.79901123-8.86399841 19.79901123-19.79899597 0-10.93500519-8.86502075-19.7990036-19.79901123-19.7990036z"
                    />
                </g>
            </svg>
        </>
    );
};

export default Logo;
