import React, { ReactElement } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { LinkProps } from './link.props';

import './link.style.scss';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

const Link = ({ className, label, to, variant, dataGALocation, external }: LinkProps): ReactElement => {
    if (!external) {
        if (to.substring(0, 8) === 'https://' || to.substring(0, 7) === 'http://') {
            external = true;
        }
    }
    return (
        <div className={`link ${className ?? ''}`} data-variant={variant ?? ''}>
            {external || to.substring(0, 7) === 'mailto:' || to.substring(0, 4) === 'tel:' ? (
                <a
                    href={to}
                    rel={external ? 'noopener noreferrer' : ''}
                    target={external ? '_blank' : ''}
                    data-tagtype="anchorlink"
                    data-ga-location={dataGALocation}
                >
                    {label}
                    {variant === 'arrow' && <ChevronIcon direction={'right'} />}
                </a>
            ) : (
                <GatsbyLink
                    to={to}
                    activeClassName="active"
                    data-tagtype="anchorlink"
                    data-ga-location={dataGALocation}
                    partiallyActive={true}
                >
                    {label}
                    {variant === 'arrow' && <ChevronIcon direction={'right'} />}
                </GatsbyLink>
            )}
        </div>
    );
};

export default Link;
